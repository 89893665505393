import React, { PureComponent } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import { connect } from 'react-redux'
import {
  Template,
  DashBoard,
  Loader,
} from '../../components'

class HomePage extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    GET_USER_ACCOUNT: PropTypes.func.isRequired,
    GET_COMPANY_CONTENT: PropTypes.func.isRequired,
    GET_INFORMATIONS_CONTENT: PropTypes.func.isRequired,
    LOADER: PropTypes.func.isRequired,
    userAccount: PropTypes.object,
    locationList: PropTypes.array,
    locationGrandList: PropTypes.array,
    accomodationList: PropTypes.array,
    daysList: PropTypes.array,
    companyContent: PropTypes.object,
    informationsContent: PropTypes.object,
    GET_LIST_CIRCUIT: PropTypes.func.isRequired,
    pensionList: PropTypes.array,
    categoryList: PropTypes.array,
    tagList: PropTypes.array,
    GET_REQUEST: PropTypes.func.isRequired,
    GET_PENSION_LIST: PropTypes.func.isRequired,
    GET_TAG_LIST: PropTypes.func.isRequired,
    GET_CATEGORY_LIST: PropTypes.func.isRequired,
    GET_DAYS_LIST: PropTypes.func.isRequired,
    GET_LOCATION_LIST: PropTypes.func.isRequired,
    GET_LOCATION_GRAND_LIST: PropTypes.func.isRequired,
    GET_ACCOMODATION_LIST: PropTypes.func.isRequired,
    GET_ROOM_LIST: PropTypes.func.isRequired,
    GET_ROOM_BY_ACCOMODATION_LIST: PropTypes.func.isRequired,
    GET_EXTENSION_LIST: PropTypes.func.isRequired,
    GET_ACTIVITY_LIST: PropTypes.func.isRequired,
    GET_PROFIL_USER_LIST: PropTypes.func.isRequired,
    GET_CUSTOMER_LIST: PropTypes.func.isRequired,
    GET_SEASON_BY_ACTIVITY_ALL: PropTypes.func.isRequired,
    GET_TRAVELER_BY_TRIP: PropTypes.func.isRequired,
    RESET_LIST_CIRCUIT: PropTypes.func.isRequired,
    GET_COLLABORATORS: PropTypes.func.isRequired,
    listCircuit: PropTypes.array,
    roomList: PropTypes.array,
    roomByAccomodationList: PropTypes.array,
    profilUserList: PropTypes.array,
    customerList: PropTypes.array,
    extensionList: PropTypes.array,
    activityList: PropTypes.array,
    numberTravelerByTrip: PropTypes.array,
    seasonByActivityAll: PropTypes.array,
    request: PropTypes.array,
    collaborators: PropTypes.array,

  }

  static defaultProps = {
    isLoading: true,
    listCircuit: [],
    userAccount: {},
    daysList: [],
    locationList: [],
    companyContent: {},
    informationsContent: {},
    accomodationList: [],
    pensionList: [],
    categoryList: [],
    tagList: [],
    locationGrandList: [],
    roomByAccomodationList: [],
    roomList: [],
    profilUserList: [],
    activityList: [],
    customerList: [],
    extensionList: [],
    numberTravelerByTrip: [],
    seasonByActivityAll: [],
    request: [],
    collaborators: [],


  }

  constructor(props) {
    super(props);
    this.isLoadingChildren = this.isLoadingChildren.bind(this)
    this.state = {
      token: localStorage.getItem('token'),
      userId: localStorage.getItem('userId'),
      filterStartDate: moment().subtract(360, 'd').format('YYYY-MM'),
      filterEndDate: moment().format('YYYY-MM'),
      // eslint-disable-next-line react/no-unused-state

    }
  }

  componentDidMount() {
    const {
      token,
      userId,
      filterStartDate,
      filterEndDate,
    } = this.state
    const {
      GET_USER_ACCOUNT,
      GET_COMPANY_CONTENT,
      userAccount,
      GET_INFORMATIONS_CONTENT,
      GET_LIST_CIRCUIT,
      GET_REQUEST,
      GET_DAYS_LIST,
      GET_PENSION_LIST,
      GET_TAG_LIST,
      GET_CATEGORY_LIST,
      GET_LOCATION_LIST,
      GET_LOCATION_GRAND_LIST,
      GET_ACCOMODATION_LIST,
      GET_ROOM_LIST,
      GET_ROOM_BY_ACCOMODATION_LIST,
      GET_EXTENSION_LIST,
      GET_ACTIVITY_LIST,
      GET_PROFIL_USER_LIST,
      GET_CUSTOMER_LIST,
      GET_TRAVELER_BY_TRIP,
      GET_SEASON_BY_ACTIVITY_ALL,
      GET_COLLABORATORS,
      LOADER,
      companyContent,
      informationsContent,
      accomodationList,
      listCircuit,
      daysList,
      locationList,
      pensionList,
      roomList,
      locationGrandList,
      roomByAccomodationList,
      profilUserList,
      customerList,
      extensionList,
      categoryList,
      tagList,
      activityList,
      numberTravelerByTrip,
      seasonByActivityAll,
      request,
      collaborators,
    } = this.props
    // Vérifie si une des listes est vide
    const isAnyListEmpty = [
      userAccount,
      companyContent,
      informationsContent,
      listCircuit,
      daysList,
      pensionList,
      locationList,
      locationGrandList,
      accomodationList,
      roomList,
      categoryList,
      tagList,
      roomByAccomodationList,
      profilUserList,
      customerList,
      extensionList,
      activityList,
      numberTravelerByTrip,
      seasonByActivityAll,
      request,
      collaborators,
    ].some(_.isEmpty);

    // Si une liste est vide, activer le loader
    if (isAnyListEmpty) {
      LOADER(true);

      if (_.isEmpty(userAccount)) { GET_USER_ACCOUNT(userId); }
      if (_.isEmpty(companyContent)) { GET_COMPANY_CONTENT(userId); }
      if (_.isEmpty(informationsContent)) { GET_INFORMATIONS_CONTENT(userId); }
      if (_.isEmpty(listCircuit)) { GET_LIST_CIRCUIT(token, filterStartDate, filterEndDate, userId); }
      if (_.isEmpty(daysList)) { GET_DAYS_LIST(userId); }
      if (_.isEmpty(pensionList)) { GET_PENSION_LIST(userId); }
      if (_.isEmpty(categoryList)) { GET_CATEGORY_LIST(userId); }
      if (_.isEmpty(tagList)) { GET_TAG_LIST(userId); }
      if (_.isEmpty(locationList)) { GET_LOCATION_LIST(userId); }
      if (_.isEmpty(locationGrandList)) { GET_LOCATION_GRAND_LIST(userId); }
      if (_.isEmpty(accomodationList)) { GET_ACCOMODATION_LIST(userId); }
      if (_.isEmpty(roomList)) { GET_ROOM_LIST(userId); }
      if (_.isEmpty(roomByAccomodationList)) { GET_ROOM_BY_ACCOMODATION_LIST(userId); }
      if (_.isEmpty(profilUserList)) { GET_PROFIL_USER_LIST(); }
      if (_.isEmpty(customerList)) { GET_CUSTOMER_LIST(userId); }
      if (_.isEmpty(extensionList)) { GET_EXTENSION_LIST(userId); }
      if (_.isEmpty(activityList)) { GET_ACTIVITY_LIST(userId); }
      if (_.isEmpty(numberTravelerByTrip)) { GET_TRAVELER_BY_TRIP(token); }
      if (_.isEmpty(seasonByActivityAll)) { GET_SEASON_BY_ACTIVITY_ALL(userId); }
      if (_.isEmpty(request)) { GET_REQUEST(token); }
      if (_.isEmpty(collaborators)) { GET_COLLABORATORS(token); }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      listCircuit,
      LOADER,
    } = this.props
    if (!_.isEmpty(listCircuit) && !_.isEqual(listCircuit, prevProps.listCircuit)) {
      LOADER(false);
    }
  }

  isLoadingChildren = () => {
    const {
      LOADER,
      RESET_LIST_CIRCUIT,
    } = this.props
    LOADER(true);
    RESET_LIST_CIRCUIT()
  }

  render() {
    const {
      isLoading,
      collaborators,
    } = this.props
    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            {isLoading ? <Loader /> : <div className="loader-null" />}
            {!_.isEmpty(collaborators)
            && (
            <DashBoard
              isLoadingChildren={() => this.isLoadingChildren()}
            />
            )
  }
          </div>
        </Template>

      </div>
    )
  }
}

const mapStateToProps = state => ({
  listCircuit: state.api.listCircuit,
  collaborators: state.collaboratorsReducer.collaborators,
  putCollaborators: state.collaboratorsReducer.putCollaborators,
  profilUserList: state.api.profilUserList,
  userAccount: state.userAccountReducer.userAccount,
  pensionList: state.api.pensionList,
  categoryList: state.api.categoryList,
  tagList: state.api.tagList,
  companyContent: state.companyReducer.companyContent,
  daysList: _.filter(state.api.daysList, d => d.id_journee !== 1 && d.active === '1'),
  locationList: _.filter(state.api.locationList, l => l.id_lieu !== 1),
  informationsContent: state.informationsReducer.informationsContent,
  locationGrandList: _.filter(state.api.locationGrandList, l => l.id_lieu_grand !== 1),
  accomodationList: state.api.accomodationList,
  roomList: state.api.roomList,
  roomByAccomodationList: state.api.roomByAccomodationList,
  customerList: state.customerReducer.customerList,
  extensionList: state.extensionReducer.extensionList,
  request: state.requestReducer.request,
  activityList: state.activityReducer.activityList,
  numberTravelerByTrip: state.travelerReducer.numberTravelerByTrip,
  seasonByActivityAll: state.api.seasonByActivityAll,
  isLoading: state.api.isLoading,
})

const mapDispatchToProps = dispatch => ({
  LOADER: isLoading => dispatch({
    type: 'LOADER',
    isLoading,
  }),
  RESET_LIST_CIRCUIT: () => dispatch({
    type: 'RESET_LIST_CIRCUIT',
  }),
  GET_USER_ACCOUNT: userId => dispatch({
    type: 'GET_USER_ACCOUNT',
    userId,
  }),
  GET_COMPANY_CONTENT: userId => dispatch({
    type: 'GET_COMPANY_CONTENT',
    userId,
  }),
  GET_INFORMATIONS_CONTENT: userId => dispatch({
    type: 'GET_INFORMATIONS_CONTENT',
    userId,
  }),
  GET_LIST_CIRCUIT: (token, dateTampon, dateTamponFin, filterAdvisor) => dispatch({
    type: 'GET_LIST_CIRCUIT',
    token,
    dateTampon,
    dateTamponFin,
    filterAdvisor,
  }),

  GET_DAYS_LIST: userId => dispatch({
    type: 'GET_DAYS_LIST',
    userId,
  }),
  GET_PENSION_LIST: userId => dispatch({
    type: 'GET_PENSION_LIST',
    userId,
  }),
  GET_CATEGORY_LIST: userId => dispatch({
    type: 'GET_CATEGORY_LIST',
    userId,
  }),
  GET_TAG_LIST: userId => dispatch({
    type: 'GET_TAG_LIST',
    userId,
  }),
  GET_LOCATION_LIST: userId => dispatch({
    type: 'GET_LOCATION_LIST',
    userId,
  }),
  GET_LOCATION_GRAND_LIST: userId => dispatch({
    type: 'GET_LOCATION_GRAND_LIST',
    userId,
  }),
  GET_ACCOMODATION_LIST: userId => dispatch({
    type: 'GET_ACCOMODATION_LIST',
    userId,
  }),
  GET_ROOM_LIST: userId => dispatch({
    type: 'GET_ROOM_LIST',
    userId,
  }),
  GET_ROOM_BY_ACCOMODATION_LIST: userId => dispatch({
    type: 'GET_ROOM_BY_ACCOMODATION_LIST',
    userId,
  }),
  GET_PROFIL_USER_LIST: () => dispatch({
    type: 'GET_PROFIL_USER_LIST',
  }),
  GET_CUSTOMER_LIST: userId => dispatch({
    type: 'GET_CUSTOMER_LIST',
    userId,
  }),
  GET_EXTENSION_LIST: userId => dispatch({
    type: 'GET_EXTENSION_LIST',
    userId,
  }),
  GET_ACTIVITY_LIST: userId => dispatch({
    type: 'GET_ACTIVITY_LIST',
    userId,
  }),
  GET_TRAVELER_BY_TRIP: token => dispatch({
    type: 'GET_TRAVELER_BY_TRIP',
    token,
  }),
  GET_SEASON_BY_ACTIVITY_ALL: userId => dispatch({
    type: 'GET_SEASON_BY_ACTIVITY_ALL',
    userId,
  }),
  GET_REQUEST: token => dispatch({
    type: 'GET_REQUEST',
    token,
  }),
  GET_COLLABORATORS: token => dispatch({
    type: 'GET_COLLABORATORS',
    token,
  }),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage))
