import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import MaterialTable from '@material-table/core';
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip'

import { ExportCsv, ExportPdf } from '@material-table/exporters';

import MenuItem from '@material-ui/core/MenuItem';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import tableIcons from '../utils/icons/icons'

import {
  colorBarGraph,

} from '../../configCSS'
import {
  Input,

  BarGraph,
} from '..'
import {
  resourcesColumns,


} from './configComponents/columns'


class DashBoard extends PureComponent {
    static propTypes = {
      listCircuit: PropTypes.array,
      userAccount: PropTypes.object,
      request: PropTypes.array,
      history: PropTypes.object.isRequired,
      GET_LIST_CIRCUIT: PropTypes.func.isRequired,
      tagList: PropTypes.array,
      categoryList: PropTypes.array,
      collaborators: PropTypes.array,
      isLoadingChildren: PropTypes.func.isRequired,
    }

      static defaultProps = {
        listCircuit: [],
        userAccount: {},
        request: [],
        collaborators: [],
        tagList: [],
        categoryList: [],
      }

      constructor(props) {
        super(props);
        this.displayGraphTripCreated = this.displayGraphTripCreated.bind(this)
        this.displayAutocompleteCategory = this.displayAutocompleteCategory.bind(this)
        this.displayAutocompleteTag = this.displayAutocompleteTag.bind(this)
        this.handleChangeAdvisor = this.handleChangeAdvisor.bind(this)
        this.handleChangeTimeline = this.handleChangeTimeline.bind(this)
        this.goToTrip = this.goToTrip.bind(this)
        this.filterOptionsTag = this.filterOptionsTag.bind(this)
        this.handleTagChange = this.handleTagChange.bind(this)
        this.handleCategoryChange = this.handleCategoryChange.bind(this)
        this.state = {
          token: localStorage.getItem('token'),
          userId: localStorage.getItem('userId'),
          filterStartDate: moment().subtract(1000, 'd').format('YYYY-MM'),
          filterEndDate: moment().format('YYYY-MM'),
          filterAdvisor: localStorage.getItem('userId'),
          filterText: '',
          filterCategory: '',
          defaultTimeline: _.reverse([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(d => moment().subtract(d, 'months').format('MMM YY'))),
          // get for labels last 12 months
          // defaultTimeline: _.reverse([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(d => moment().subtract(d, 'months').format('MMM YY'))),
          labelsTimeline: _.reverse([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(d => moment().subtract(d, 'months').format('MMM YY'))),
          statusSelected: ['in progress', 'confirmed', 'confirmed-booked', 'completed', 'lost', 'no answer', 'canceled', 'posponed', 'old version'],
          // keyTab: 0,
          // EtatlistCircuit: [],

        };
      }


      componentDidMount() {
        //    if (!localStorage.getItem('isLogged')) {
        //    history.push('/')
        //  }

      }

      componentWillReceiveProps() {
      }

      componentDidUpdate() {

      }


      fillLabelsTimeline = (yearSelected) => {
        const { defaultTimeline } = this.state
        const labelsTimelineSelected = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        // get the 12 last months where a trip was created
        if (yearSelected === 'Last 12 months') {
          return defaultTimeline.slice(defaultTimeline.length - 12)
        }
        // build an array with the 12 months for the year selected
        return labelsTimelineSelected.map((d, i) => moment(`${i + 1}-${yearSelected}`, 'M-YYYY').format('MMM YY'))
      }

      handleChangeTimeline = (event) => {
        const { value } = event.target

        this.setState({
          labelsTimeline: this.fillLabelsTimeline(value),
        })
      }

      handleChangeAdvisor = (event) => {
        // value is an array of value checked ["name1", "name2", ...]
        this.setState({
          statusSelected: [
            ...event.target.value,
          ],
        })
      };

      handleChange = (e) => {
        const {
          value, name,
        } = e.target

        const {
          token,
          userId,
          filterEndDate,
          filterStartDate,
          filterAdvisor,
        } = this.state
        const {
          GET_LIST_CIRCUIT,
          isLoadingChildren,
        } = this.props

        this.setState({
          [name]: value,
        })
        if (name === 'filterAdvisor') {
          GET_LIST_CIRCUIT(token, filterStartDate, filterEndDate, value || userId)
          isLoadingChildren()
        }


        if (name === 'filterStartDate') {
          GET_LIST_CIRCUIT(token, value || '2020-01', filterEndDate, filterAdvisor)
          isLoadingChildren()
        }
        if (name === 'filterEndDate') {
          GET_LIST_CIRCUIT(token, filterStartDate, value || '2020-01', filterAdvisor)
          isLoadingChildren()
        }
      }

      displayGraphTripCreated = () => {
        const {
          labelsTimeline,
          statusSelected,
        } = this.state

        const
          {
            listCircuit,
          } = this.props

        // get number of trip created by advisor and by month
        const datasetsFindAdvisor = indexAdvisor => labelsTimeline.map(l => (listCircuit.filter(t => t.status === statusSelected[indexAdvisor] && l === moment(t.date_modif_status, 'YYYY-MM-DD').format('MMM YY'))).length)

        // build datastes for graph
        const datasets = statusSelected.map((ad, i) => (
          {
            label: ad,
            data: datasetsFindAdvisor(i),
            backgroundColor: colorBarGraph[i],
          }
        ))

        return (
          <div className="graph-main-div">


            <BarGraph
              id="tripPerAdvisorDashBoard"
              key="tripPerAdvisorDashBoard"
              labels={labelsTimeline}
              datasets={datasets}
              message="Trip created per advisor"
            />
          </div>
        )
      }

        filterOptionsTag = list => (options, params) => {
          const filtered = _.filter(list, d => d.tag.toLowerCase().indexOf(params.inputValue.toLowerCase()) > -1)
          // Suggest the creation of a new value
          // Suggest the creation of a new value
          if (params.inputValue !== '' && !filtered.some(option => option.tag === params.inputValue)) {
            filtered.push({ tag: params.inputValue });
          }
          return filtered;
        }

      handleTagChange = (newValue) => {
        if (!newValue || newValue.length === 0) {
          this.setState({

            filterTag: '',

          }); // Réinitialiser le champ à une liste vide
        } else {
          this.setState({
            filterTag: newValue.map(option => (typeof option === 'string' ? option : option.tag)),

          });
        }
      }


       displayAutocompleteTag = () => {
         const {
           filterTag,
         } = this.state
         const {
           tagList,
           userAccount,
         } = this.props
         const tagListLangage = tagList.filter(tL => tL.langage === userAccount.language)
         const tagTripListTab = filterTag ? _.split(filterTag, ',') : [];
         return (
           <Autocomplete
             multiple
             id="tag-field"
             value={tagTripListTab.map(item => (typeof item === 'string' ? { tag: item } : item))} // Adapter les valeurs pour correspondre à des objets
             options={tagListLangage}
             filterOptions={this.filterOptionsTag(tagListLangage)}
             onChange={(event, newValue, reason) => {
               const updatedValue = newValue.map(item => (typeof item === 'string' ? { tag: item } : item));
               this.handleTagChange(updatedValue, reason); // Mettre à jour l'état
             }}
             selectOnFocus
             clearOnBlur
             handleHomeEndKeys
             getOptionLabel={option => (typeof option === 'string' ? option : option.tag || '')} // Support pour les chaînes et les objets
             freeSolo
             renderTags={(value, getTagProps) => value.map((option, index) => (
               <Chip
                 variant="outlined"
                 label={option.tag || option} // Gérer chaînes et objets
                 {...getTagProps({ index })}
               />
             ))
           }
             renderOption={option => option.tag || ''} // Affiche l'e-mail dans la liste
             renderInput={params => (
               <Input
                 {...params}
                 variant="outlined"
                 label="Tag"
                 margin="normal"
                 className="input-white"
               />
             )}
           />

         )
       }


        handleCategoryChange = (content) => {
          this.setState({

            filterCategory: content ? content.id_category : '',
          });
        };

     displayAutocompleteCategory = () => {
       const { filterCategory } = this.state;
       const {
         categoryList,
         userAccount,
       } = this.props;

       const selectedCategoryId = _.toNumber(filterCategory);
       const selectedCategory = categoryList.find(aL => aL.id_category === selectedCategoryId) || null;

       const categoryListLangage = categoryList.filter(tL => tL.langage === userAccount.language);
       return (
         <Autocomplete
           id="tag-field"
           label="Category"
           selectOnFocus
           clearOnBlur
           handleHomeEndKeys
           value={selectedCategory}
           onChange={(event, newValue) => {
             this.handleCategoryChange(newValue);
           }}
           options={categoryListLangage}
           getOptionLabel={option => option.category}
           renderInput={params => (
             <Input
               {...params}
               margin="normal"
               placeholder="Select a category"
               variant="outlined"
               required
             />
           )}
         />
       );
     };

      displayQuote = (status, listCircuitFiltered) => {
        const {
          statusSelected,
        } = this.state
        const EtatlistCircuitbyID = listCircuitFiltered.filter(c => c.status === status)

        const ComptelistCircuitbyID = listCircuitFiltered.filter(c => c.status === status).length || 0;
        const index = statusSelected.indexOf(status);
        const backgroundColor = index !== -1 ? colorBarGraph[index] : '#ccc';
        const styles = {
          summary: {
            backgroundColor: '#e7e9ef', // Fond gris-bleu
            padding: '8px 16px', // Espacement
            borderRadius: '4px', // Coins arrondis
          },
        };

        const StyledAccordionSummary = withStyles(styles)(({ classes, ...props }) => (
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.summary}
            {...props}
          />
        ));

        return (
          <div>
            <Accordion>
              <StyledAccordionSummary
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box className="TitreDashBoard" display="flex" alignItems="center">
                  <Typography
                    style={{
                      backgroundColor: backgroundColor,
                      fontSize: 'large',
                      textAlign: 'center',
                      borderRadius: '15px',
                      padding: '4px 12px',
                    }}
                    component="span"
                  >
                    STATUS {status}
                  </Typography>
                  <Badge
                    badgeContent={ComptelistCircuitbyID}
                    color="primary"
                    showZero
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    style={{ marginLeft: '18px' }}
                  />
                </Box>
              </StyledAccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <MaterialTable
                      style={{ marginBottom: '30px' }}
                      data={EtatlistCircuitbyID}
                      title=""
                      columns={resourcesColumns}
                      icons={tableIcons}
                      options={{
                        exportMenu: [{
                          label: 'Export PDF',
                          exportFunc: (cols, datas) => ExportPdf(cols, datas, 'Export list'),
                        }, {
                          label: 'Export CSV',
                          exportFunc: (cols, datas) => ExportCsv(cols, datas, 'Export list'),
                        }],
                        search: true,
                        pageSize: 10,
                        sorting: true,
                        headerStyle: { textAlign: 'left' },
                        actionsColumnIndex: -1,
                        columnResizable: true,
                        doubleHorizontalScroll: true,
                        selectableRows: 'single',
                        selectableRowsOnClick: true,
                        columnsButton: true,
                        customToolbar: false,
                        filter: false,
                      }}
                      actions={[
                        {
                          icon: tableIcons.Show,
                          tooltip: 'Show trip',

                          onClick: (event, rowData) => {
                            const NomCircuit = rowData.id_devis
                            this.goToTrip({
                              source: 'homePage',
                              NomCircuit,
                            })
                          },
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        )
      }

      goTo = (dest) => {
        const { history } = this.props
        history.push(dest)
      }

       goToTrip = (data = {}) => {
         const { history } = this.props
         history.push('trip', { ...data })
       }

       render() {
         const
           {
             collaborators,
             listCircuit,
             categoryList,
           } = this.props
         const {
           filterStartDate,
           filterEndDate,
           filterAdvisor,
           filterText,
           filterCategory,
           filterTag,
         } = this.state
         const selectedCategoryId = _.toNumber(filterCategory);
         const selectedCategory = categoryList.find(aL => aL.id_category === selectedCategoryId) || null;
         const listCircuitFiltered = filterText
           ? listCircuit.filter(lC => (lC.nom_devis && lC.nom_devis.toLowerCase().includes(filterText.toLowerCase()))
             || (lC.demandeur && lC.demandeur.toLowerCase().includes(filterText.toLowerCase()))
             || (lC.e_mail && lC.e_mail.toLowerCase().includes(filterText.toLowerCase())))
           : listCircuit;
         const listCircuitFiltered2 = filterCategory ? listCircuitFiltered.filter(lC => lC.category === selectedCategory.category)
           : listCircuitFiltered;
         const listCircuitFiltered3 = filterTag ? listCircuitFiltered2.filter(lC => lC.nombre_chambre
            && filterTag.every(tag => lC.nombre_chambre
              .split(',')
              .map(t => t.trim().toLowerCase())
              .includes(tag.toLowerCase())))
           : listCircuitFiltered2;
         return (
           <div>
             <Grid container spacing={2} justifyContent="center">
               <Grid item lg={5} md={12}>
                 <Grid container spacing={2}>

                   <Grid item md="auto" xs={12}>
                     <Input
                       type="month"
                       name="filterStartDate"
                       value={filterStartDate || '2020-01'}
                       InputLabelProps={{
                         shrink: true,
                       }}
                       onChange={e => this.handleChange(e)}
                       label="Quote create from"
                       margin="normal"
                       variant="outlined"
                     />
                   </Grid>
                   <Grid item md="auto" xs={12}>
                     <Input
                       type="month"
                       name="filterEndDate"
                       value={filterEndDate || '2020-01'}
                       InputLabelProps={{
                         shrink: true,
                       }}
                       onChange={e => this.handleChange(e)}
                       label="to"
                       margin="normal"
                       variant="outlined"
                     />
                   </Grid>
                   <Grid item md={12} xs={12}>
                     <Input
                       id="advisor"
                       label="travel agent"
                       name="filterAdvisor"
                       select
                       margin="normal"
                       variant="outlined"
                       value={filterAdvisor}
                       className="largeWidth"
                       onChange={e => this.handleChange(e)}
                      // eslint-disable-next-line react/jsx-closing-bracket-location
            >
                       {collaborators && _.map(collaborators, (collab, k) => (
                         <MenuItem key={k} value={collab.id_conseiller}>{collab.lastname} {collab.firstname}</MenuItem>
                       ))}
                       <MenuItem key="everybody" value="all">all</MenuItem>
                       <menuItem />
                     </Input>
                   </Grid>
                   <Grid item md={12} xs={12}>
                     <Input
                       id="filterText"
                       label="Search (tour name, client name, email adress)"
                       name="filterText"
                       value={filterText}
                       onChange={e => this.handleChange(e)}
                       margin="normal"
                       variant="outlined"
                       className="largeWidth"
                     />
                   </Grid>
                   <Grid item md={6} xs={12}>
                     {this.displayAutocompleteCategory()}
                   </Grid>
                   <Grid item md={6} xs={12}>
                     {this.displayAutocompleteTag()}
                   </Grid>
                 </Grid>

               </Grid>
               <Grid item lg={7} md={12}>
                 {this.displayGraphTripCreated()}
               </Grid>
             </Grid>
             {this.displayQuote('template', listCircuitFiltered3)}
             {this.displayQuote('in progress', listCircuitFiltered3)}
             {this.displayQuote('confirmed', listCircuitFiltered3)}
             {this.displayQuote('confirmed-booked', listCircuitFiltered3)}
             {this.displayQuote('completed', listCircuitFiltered3)}
             {this.displayQuote('lost', listCircuitFiltered3)}
             {this.displayQuote('no answer', listCircuitFiltered3)}
             {this.displayQuote('canceled', listCircuitFiltered3)}
             {this.displayQuote('posponed', listCircuitFiltered3)}
             {this.displayQuote('old version', listCircuitFiltered3)}
           </div>
         )
       }
}

const mapStateToProps = state => ({
  listCircuit: state.api.listCircuit,
  userAccount: state.userAccountReducer.userAccount,
  tagList: state.api.tagList,
  categoryList: state.api.categoryList,
  request: state.requestReducer.request,
  tripContent: state.api.tripContent,
  collaborators: state.collaboratorsReducer.collaborators,
})

const mapDispatchToProps = dispatch => ({

  GET_LIST_CIRCUIT: (token, dateTampon, dateTamponFin, filterAdvisor) => dispatch({
    type: 'GET_LIST_CIRCUIT',
    token,
    dateTampon,
    dateTamponFin,
    filterAdvisor,
  }),

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashBoard))
