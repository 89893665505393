// general list
export const GET_LIST_CIRCUIT_RESULTS = 'GET_LIST_CIRCUIT_RESULTS'
export const GET_STATUS_LIST_RESULTS = 'GET_STATUS_LIST_RESULTS'
export const GET_DAYS_LIST_RESULTS = 'GET_DAYS_LIST_RESULTS'
export const GET_LOCATION_LIST_RESULTS = 'GET_LOCATION_LIST_RESULTS'
export const GET_LOCATION_GRAND_LIST_RESULTS = 'GET_LOCATION_GRAND_LIST_RESULTS'
export const GET_PENSION_LIST_RESULTS = 'GET_PENSION_LIST_RESULTS'
export const GET_CATEGORY_LIST_RESULTS = 'GET_CATEGORY_LIST_RESULTS'
export const GET_TAG_LIST_RESULTS = 'GET_TAG_LIST_RESULTS'
export const GET_ACCOMODATION_LIST_RESULTS = 'GET_ACCOMODATION_LIST_RESULTS'
export const GET_ROOM_LIST_RESULTS = 'GET_ROOM_LIST_RESULTS'
export const GET_ACCOMODATION_TYPE_LIST_RESULTS = 'GET_ACCOMODATION_TYPE_LIST_RESULTS'
export const GET_ROOM_BY_ACCOMODATION_LIST_RESULTS = 'GET_ROOM_BY_ACCOMODATION_LIST_RESULTS'
export const GET_ROOM_BY_ACCOMODATION_RESULTS = 'GET_ROOM_BY_ACCOMODATION_RESULTS'
export const GET_SEASON_BY_ACCOMODATION_RESULTS = 'GET_SEASON_BY_ACCOMODATION_RESULTS'
export const GET_SEASON_BY_ACTIVITY_RESULTS = 'GET_SEASON_BY_ACTIVITY_RESULTS'
export const GET_SEASON_BY_ACTIVITY_ALL_RESULTS = 'GET_SEASON_BY_ACTIVITY_ALL_RESULTS'
export const GET_PROFIL_USER_LIST_RESULTS = 'GET_PROFIL_USER_LIST_RESULTS'
export const RESET_LIST_CIRCUIT = 'RESET_LIST_CIRCUIT'
// specific trip
export const ID_TRIP_SELECT = 'ID_TRIP_SELECT'
export const ROW_TRIP_SELECT = 'ROW_TRIP_SELECT'
export const DELETE_TRIP_RESULTS = 'DELETE_TRIP_RESULTS'
export const RESET_DELETE_TRIP = 'RESET_DELETE_TRIP'
export const GET_TRIP_DETAILS_RESULTS = 'GET_TRIP_DETAILS_RESULTS'
export const PUT_TRIP_DETAILS_RESULTS = 'PUT_TRIP_DETAILS_RESULTS'
export const PUT_TRIP_DETAILS_URL_RESULTS = 'PUT_TRIP_DETAILS_URL_RESULTS'
export const PUT_TRIP_DEVIS_FINAL_RESULTS = 'PUT_TRIP_DEVIS_FINAL_RESULTS'
export const RESET_TRIP_DETAILS = 'RESET_TRIP_DETAILS'
export const RESET_TRIP_DEVIS_FINAL = 'RESET_TRIP_DEVIS_FINAL'
export const GET_TRIP_CONTENT_RESULTS = 'GET_TRIP_CONTENT_RESULTS'
export const PUT_TRIP_CONTENT_RESULTS = 'PUT_TRIP_CONTENT_RESULTS'
export const RESET_TRIP_CONTENT = 'RESET_TRIP_CONTENT'
export const GET_TRIP_ALLDAYS_RESULTS = 'GET_TRIP_ALLDAYS_RESULTS'
export const GET_TRIP_ALLDAYS_ACCOMODATION_RESULTS = 'GET_TRIP_ALLDAYS_ACCOMODATION_RESULTS'
export const GET_TRIP_ACCOMODATION_RESULTS = 'GET_TRIP_ACCOMODATION_RESULTS'
export const GET_TRIP_ACCOMODATION_OPT1_RESULTS = 'GET_TRIP_ACCOMODATION_OPT1_RESULTS'
export const GET_TRIP_ACCOMODATION_OPT2_RESULTS = 'GET_TRIP_ACCOMODATION_OPT2_RESULTS'
export const GET_TRIP_SEASON_BY_ACCOMODATION_RESULTS = 'GET_TRIP_SEASON_BY_ACCOMODATION_RESULTS'
export const GET_TRIP_SEASON_BY_ACCOMODATION_OPT1_RESULTS = 'GET_TRIP_SEASON_BY_ACCOMODATION_OPT1_RESULTS'
export const GET_TRIP_SEASON_BY_ACCOMODATION_OPT2_RESULTS = 'GET_TRIP_SEASON_BY_ACCOMODATION_OPT2_RESULTS'
export const GET_TRIP_PENSION_RESULTS = '_RESULTS'
export const GET_TRIP_DEVIS_FINAL_RESULTS = 'GET_TRIP_DEVIS_FINAL_RESULTS'
export const GET_TRIP_ROOM_RESULTS = 'GET_TRIP_ROOM_RESULTS'
export const GET_TRIP_ROOM_OPT1_RESULTS = 'GET_TRIP_ROOM_OPT1_RESULTS'
export const GET_TRIP_ROOM_OPT2_RESULTS = 'GET_TRIP_ROOM_OPT2_RESULTS'
export const RESET_TRIP_INFORMATION = 'RESET_TRIP_INFORMATION'
// Management
export const PUT_DAY_CONTENT_RESULTS = 'PUT_DAY_CONTENT_RESULTS'
export const DELETE_DAY_CONTENT_RESULTS = 'DELETE_DAY_CONTENT_RESULTS'
export const RESET_DAY_CONTENT = 'RESET_DAY_CONTENT'
export const PUT_ACCOMODATION_CONTENT_RESULTS = 'PUT_ACCOMODATION_CONTENT_RESULTS'
export const DELETE_ACCOMODATION_CONTENT_RESULTS = 'DELETE_ACCOMODATION_CONTENT_RESULTS'
export const RESET_ACCOMODATION_CONTENT = 'RESET_ACCOMODATION_CONTENT'
export const PUT_LOCATION_CONTENT_RESULTS = 'PUT_LOCATION_CONTENT_RESULTS'
export const DELETE_LOCATION_CONTENT_RESULTS = 'DELETE_LOCATION_CONTENT_RESULTS'
export const DELETE_LOCATION_GRAND_CONTENT_RESULTS = 'DELETE_LOCATION_GRAND_CONTENT_RESULTS'
export const PUT_LOCATION_GRAND_CONTENT_RESULTS = 'PUT_LOCATION_GRAND_CONTENT_RESULTS'
export const RESET_LOCATION_CONTENT = 'RESET_LOCATION_CONTENT'
export const RESET_LOCATION_GRAND_CONTENT = 'RESET_LOCATION_GRAND_CONTENT'
export const PUT_PENSION_CONTENT_RESULTS = 'PUT_PENSION_CONTENT_RESULTS'
export const DELETE_PENSION_CONTENT_RESULTS = 'DELETE_PENSION_CONTENT_RESULTS'
export const RESET_PENSION_CONTENT = 'RESET_PENSION_CONTENT'
export const PUT_CATEGORY_CONTENT_RESULTS = 'PUT_CATEGORY_CONTENT_RESULTS'
export const DELETE_CATEGORY_CONTENT_RESULTS = 'DELETE_CATEGORY_CONTENT_RESULTS'
export const RESET_CATEGORY_CONTENT = 'RESET_CATEGORY_CONTENT'
export const PUT_TAG_CONTENT_RESULTS = 'PUT_TAG_CONTENT_RESULTS'
export const DELETE_TAG_CONTENT_RESULTS = 'DELETE_TAG_CONTENT_RESULTS'
export const RESET_TAG_CONTENT = 'RESET_TAG_CONTENT'
export const PUT_ROOM_CONTENT_RESULTS = 'PUT_ROOM_CONTENT_RESULTS'
export const DELETE_ROOM_CONTENT_RESULTS = 'DELETE_ROOM_CONTENT_RESULTS'
export const RESET_ROOM_CONTENT = 'RESET_ROOM_CONTENT'
export const PUT_ACCOMODATION_TYPE_CONTENT_RESULTS = 'PUT_ACCOMODATION_TYPE_CONTENT_RESULTS'
export const DELETE_ACCOMODATION_TYPE_CONTENT_RESULTS = 'DELETE_ACCOMODATION_TYPE_CONTENT_RESULTS'
export const RESET_ACCOMODATION_TYPE_CONTENT = 'RESET_ACCOMODATION_TYPE_CONTENT'
export const PUT_ROOM_BY_ACCOMODATION_RESULTS = 'PUT_ROOM_BY_ACCOMODATION_RESULTS'
export const PUT_SEASON_BY_ACCOMODATION_RESULTS = 'PUT_SEASON_BY_ACCOMODATION_RESULTS'
export const PUT_SEASON_BY_ACTIVITY_RESULTS = 'PUT_SEASON_BY_ACTIVITY_RESULTS'
export const PUT_ROOM_PRICE_BY_ACCOMODATION_RESULTS = 'PUT_ROOM_PRICE_BY_ACCOMODATION_RESULTS'
export const DELETE_ROOM_BY_ACCOMODATION_RESULTS = 'DELETE_ROOM_BY_ACCOMODATION_RESULTS'
export const DELETE_SEASON_BY_ACCOMODATION_RESULTS = 'DELETE_SEASON_BY_ACCOMODATION_RESULTS'
export const DELETE_SEASON_BY_ACTIVITY_RESULTS = 'DELETE_SEASON_BY_ACTIVITY_RESULTS'
export const RESET_ROOM_BY_ACCOMODATION = 'RESET_ROOM_BY_ACCOMODATION'
export const RESET_ROOM_PRICE_BY_ACCOMODATION = 'RESET_ROOM_PRICE_BY_ACCOMODATION'
export const RESET_SEASON_BY_ACCOMODATION = 'RESET_SEASON_BY_ACCOMODATION'
export const RESET_SEASON_BY_ACTIVITY = 'RESET_SEASON_BY_ACTIVITY'
// specific APP
export const VALIDATE_STEP = 'VALIDATE_STEP'
export const ACTIVE_STEP = 'ACTIVE_STEP'
export const LOADER = 'LOADER'
export const RESET_APP = 'RESET_APP'
// Users
export const POST_SIGNIN_RESULTS = 'POST_SIGNIN_RESULTS'
export const GET_UNVALID_TOKEN_LIST_RESULTS = 'GET_UNVALID_TOKEN_LIST_RESULTS'

// ***********************************
// Trip
export const rowTripSelect = data => ({
  type: ROW_TRIP_SELECT,
  rowTripSelect: data,
})

export const idTripSelect = data => ({
  type: ID_TRIP_SELECT,
  idTripSelected: data.idTripSelected,
  isNewTrip: data.isNewTrip,
  isDetailModified: data.isDetailModified,
  isDuplicatedTrip: data.isDuplicatedTrip,
})

export const deleteTripResults = data => ({
  type: DELETE_TRIP_RESULTS,
  deletedTrip: data,
})

export const resetDeleteTrip = () => ({
  type: RESET_DELETE_TRIP,
})

// Trip Details
export const getTripDetailsResults = data => ({
  type: GET_TRIP_DETAILS_RESULTS,
  tripDetails: data,
})

export const putTripDetailsResults = data => ({
  type: PUT_TRIP_DETAILS_RESULTS,
  putTripDetails: data,
})

export const putTripDetailsUrlResults = data => ({
  type: PUT_TRIP_DETAILS_URL_RESULTS,
  putTripDetailsUrl: data,
})
export const putTripDevisFinalResults = data => ({
  type: PUT_TRIP_DEVIS_FINAL_RESULTS,
  putTripDevisFinal: data,
})

export const resetTripDetails = () => ({
  type: RESET_TRIP_DETAILS,
})

export const resetTripDevisFinal = () => ({
  type: RESET_TRIP_DEVIS_FINAL,
})

// Trip content
export const getTripContentResults = data => ({
  type: GET_TRIP_CONTENT_RESULTS,
  tripContent: data,
})

export const putTripContentResults = data => ({
  type: PUT_TRIP_CONTENT_RESULTS,
  putTripContent: data,
})

export const resetTripContent = () => ({
  type: RESET_TRIP_CONTENT,
})

export const getTripAllDaysResults = data => ({
  type: GET_TRIP_ALLDAYS_RESULTS,
  tripAllDays: data,
})
export const getTripAllDaysAccomodationResults = data => ({
  type: GET_TRIP_ALLDAYS_ACCOMODATION_RESULTS,
  tripAllDaysAccomodations: data,
})

export const getTripAccomodationResults = data => ({
  type: GET_TRIP_ACCOMODATION_RESULTS,
  tripAccomodation: data,
})

export const getTripSeasonByAccomodationResults = data => ({
  type: GET_TRIP_SEASON_BY_ACCOMODATION_RESULTS,
  tripSeasonByAccomodation: data,
})

export const getTripSeasonByAccomodationOpt1Results = data => ({
  type: GET_TRIP_SEASON_BY_ACCOMODATION_OPT1_RESULTS,
  tripSeasonByAccomodationOpt1: data,
})

export const getTripSeasonByAccomodationOpt2Results = data => ({
  type: GET_TRIP_SEASON_BY_ACCOMODATION_OPT2_RESULTS,
  tripSeasonByAccomodationOpt2: data,
})

export const getTripAccomodationOpt1Results = data => ({
  type: GET_TRIP_ACCOMODATION_OPT1_RESULTS,
  tripAccomodationOpt1: data,
})

export const getTripAccomodationOpt2Results = data => ({
  type: GET_TRIP_ACCOMODATION_OPT2_RESULTS,
  tripAccomodationOpt2: data,
})

export const getTripPensionResults = data => ({
  type: GET_TRIP_PENSION_RESULTS,
  tripPension: data,
})

export const getTripDevisFinalResults = data => ({
  type: GET_TRIP_DEVIS_FINAL_RESULTS,
  tripDevisFinal: data,
})

export const getTripRoomResults = data => ({
  type: GET_TRIP_ROOM_RESULTS,
  tripRoom: data,
})

export const getTripRoomOpt1Results = data => ({
  type: GET_TRIP_ROOM_OPT1_RESULTS,
  tripRoomOpt1: data,
})

export const getTripRoomOpt2Results = data => ({
  type: GET_TRIP_ROOM_OPT2_RESULTS,
  tripRoomOpt2: data,
})


// Lists for select component
export const getListCircuitResults = data => ({
  type: GET_LIST_CIRCUIT_RESULTS,
  listCircuit: data,
})

export const getStatusListResults = data => ({
  type: GET_STATUS_LIST_RESULTS,
  statusList: data,
})

export const getDaysListResults = data => ({
  type: GET_DAYS_LIST_RESULTS,
  daysList: data,
})

export const getLocationListResults = data => ({
  type: GET_LOCATION_LIST_RESULTS,
  locationList: data,
})

export const getLocationGrandListResults = data => ({
  type: GET_LOCATION_GRAND_LIST_RESULTS,
  locationGrandList: data,
})

export const getPensionListResults = data => ({
  type: GET_PENSION_LIST_RESULTS,
  pensionList: data,
})

export const getCategoryListResults = data => ({
  type: GET_CATEGORY_LIST_RESULTS,
  categoryList: data,
})

export const getTagListResults = data => ({
  type: GET_TAG_LIST_RESULTS,
  tagList: data,
})

export const getAccomodationListResults = data => ({
  type: GET_ACCOMODATION_LIST_RESULTS,
  accomodationList: data,
})

export const getRoomListResults = data => ({
  type: GET_ROOM_LIST_RESULTS,
  roomList: data,
})

export const getAccomodationTypeListResults = data => ({
  type: GET_ACCOMODATION_TYPE_LIST_RESULTS,
  accomodationTypeList: data,
})

export const getRoomByAccomodationListResults = data => ({
  type: GET_ROOM_BY_ACCOMODATION_LIST_RESULTS,
  roomByAccomodationList: data,
})
export const getRoomByAccomodationResults = data => ({
  type: GET_ROOM_BY_ACCOMODATION_RESULTS,
  roomByAccomodation: data,
})
export const getSeasonByAccomodationResults = data => ({
  type: GET_SEASON_BY_ACCOMODATION_RESULTS,
  seasonByAccomodation: data,
})
export const getSeasonByActivityResults = data => ({
  type: GET_SEASON_BY_ACTIVITY_RESULTS,
  seasonByActivity: data,
})
export const getSeasonByActivityAllResults = data => ({
  type: GET_SEASON_BY_ACTIVITY_ALL_RESULTS,
  seasonByActivityAll: data,
})
export const getProfilUserListResults = data => ({
  type: GET_PROFIL_USER_LIST_RESULTS,
  profilUserList: data,
})

// Management
export const putDayContentResults = data => ({
  type: PUT_DAY_CONTENT_RESULTS,
  putDayContent: data,
})

export const deleteDayContentResults = data => ({
  type: DELETE_DAY_CONTENT_RESULTS,
  deleteDayContent: data,
})

export const resetDayContent = () => ({
  type: RESET_DAY_CONTENT,
})

export const resetListCircuit = () => ({
  type: RESET_LIST_CIRCUIT,
})

export const putAccomodationContentResults = data => ({
  type: PUT_ACCOMODATION_CONTENT_RESULTS,
  putAccomodationContent: data,
})

export const deleteAccomodationContentResults = data => ({
  type: DELETE_ACCOMODATION_CONTENT_RESULTS,
  deleteAccomodationContent: data,
})

export const resetAccomodationContent = () => ({
  type: RESET_ACCOMODATION_CONTENT,
})

export const putLocationContentResults = data => ({
  type: PUT_LOCATION_CONTENT_RESULTS,
  putLocationContent: data,
})

export const putLocationGrandContentResults = data => ({
  type: PUT_LOCATION_GRAND_CONTENT_RESULTS,
  putLocationGrandContent: data,
})

export const deleteLocationContentResults = data => ({
  type: DELETE_LOCATION_CONTENT_RESULTS,
  deleteLocationContent: data,
})

export const deleteLocationGrandContentResults = data => ({
  type: DELETE_LOCATION_GRAND_CONTENT_RESULTS,
  deleteLocationGrandContent: data,
})

export const resetLocationContent = () => ({
  type: RESET_LOCATION_CONTENT,
})

export const resetLocationGrandContent = () => ({
  type: RESET_LOCATION_GRAND_CONTENT,
})

export const putPensionContentResults = data => ({
  type: PUT_PENSION_CONTENT_RESULTS,
  putPensionContent: data,
})

export const deletePensionContentResults = data => ({
  type: DELETE_PENSION_CONTENT_RESULTS,
  deletePensionContent: data,
})

export const resetPensionContent = () => ({
  type: RESET_PENSION_CONTENT,
})

export const putCategoryContentResults = data => ({
  type: PUT_CATEGORY_CONTENT_RESULTS,
  putCategoryContent: data,
})

export const deleteCategoryContentResults = data => ({
  type: DELETE_CATEGORY_CONTENT_RESULTS,
  deleteCategoryContent: data,
})

export const resetCategoryContent = () => ({
  type: RESET_CATEGORY_CONTENT,
})

export const putTagContentResults = data => ({
  type: PUT_TAG_CONTENT_RESULTS,
  putTagContent: data,
})

export const deleteTagContentResults = data => ({
  type: DELETE_TAG_CONTENT_RESULTS,
  deleteTagContent: data,
})

export const resetTagContent = () => ({
  type: RESET_TAG_CONTENT,
})

export const putRoomContentResults = data => ({
  type: PUT_ROOM_CONTENT_RESULTS,
  putRoomContent: data,
})

export const deleteRoomContentResults = data => ({
  type: DELETE_ROOM_CONTENT_RESULTS,
  deleteRoomContent: data,
})

export const resetRoomContent = () => ({
  type: RESET_ROOM_CONTENT,
})

export const putAccomodationTypeContentResults = data => ({
  type: PUT_ACCOMODATION_TYPE_CONTENT_RESULTS,
  putAccomodationTypeContent: data,
})

export const deleteAccomodationTypeContentResults = data => ({
  type: DELETE_ACCOMODATION_TYPE_CONTENT_RESULTS,
  deleteAccomodationTypeContent: data,
})

export const resetAccomodationTypeContent = () => ({
  type: RESET_ACCOMODATION_TYPE_CONTENT,
})

export const putRoomByAccomodationResults = data => ({
  type: PUT_ROOM_BY_ACCOMODATION_RESULTS,
  putRoomByAccomodation: data,
})
export const putSeasonByAccomodationResults = data => ({
  type: PUT_SEASON_BY_ACCOMODATION_RESULTS,
  putSeasonByAccomodation: data,
})
export const putSeasonByActivityResults = data => ({
  type: PUT_SEASON_BY_ACTIVITY_RESULTS,
  putSeasonByActivity: data,
})
export const putRoomPriceByAccomodationResults = data => ({
  type: PUT_ROOM_PRICE_BY_ACCOMODATION_RESULTS,
  putRoomPriceByAccomodation: data,
})

export const deleteRoomByAccomodationResults = data => ({
  type: DELETE_ROOM_BY_ACCOMODATION_RESULTS,
  deleteRoomByAccomodation: data,
})
export const deleteSeasonByAccomodationResults = data => ({
  type: DELETE_SEASON_BY_ACCOMODATION_RESULTS,
  deleteSeasonByAccomodation: data,
})
export const deleteSeasonByActivityResults = data => ({
  type: DELETE_SEASON_BY_ACTIVITY_RESULTS,
  deleteSeasonByActivity: data,
})

export const resetRoomByAccomodation = () => ({
  type: RESET_ROOM_BY_ACCOMODATION,
})
export const resetRoomPriceByAccomodation = () => ({
  type: RESET_ROOM_PRICE_BY_ACCOMODATION,
})
export const resetSeasonByAccomodation = () => ({
  type: RESET_SEASON_BY_ACCOMODATION,
})
export const resetSeasonByActivity = () => ({
  type: RESET_SEASON_BY_ACTIVITY,
})

export const resetTripInformation = () => ({
  type: RESET_TRIP_INFORMATION,
})

// App
export const activeStep = data => ({
  type: ACTIVE_STEP,
  activeStep: data,
})

export const validateTripStep = data => ({
  type: VALIDATE_STEP,
  page: data.page,
  value: data.value,
  progression: data.progression,
})

export const loader = data => ({
  type: LOADER,
  isLoading: data,
})

export const resetApp = () => ({
  type: RESET_APP,
})

// Users
export const postUserSignInResults = data => ({
  type: POST_SIGNIN_RESULTS,
  userLog: data,
})

export const getUnvalidTokenListResults = data => ({
  type: GET_UNVALID_TOKEN_LIST_RESULTS,
  unvalidTokenList: data,
})
