import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import Tooltip from '@material-ui/core/Tooltip';
import {
  Template,
  Input,
  ActionButton,
  ModalComponent,
} from '../../../components'
import { CssGrid } from '../../../configCSS'
import modify from '../../../assets/images/modify.png'
import modify2x from '../../../assets/images/modify@2x.png'
import modify3x from '../../../assets/images/modify@3x.png'
import deleteCross from '../../../assets/images/delete-cross.png'
import deleteCross2x from '../../../assets/images/delete-cross@2x.png'
import deleteCross3x from '../../../assets/images/delete-cross@3x.png'

class Tag extends PureComponent {
  static propTypes = {
    GET_TAG_LIST: PropTypes.func.isRequired,
    RESET_TAG_CONTENT: PropTypes.func.isRequired,
    PUT_TAG_CONTENT: PropTypes.func.isRequired,
    DELETE_TAG_CONTENT: PropTypes.func.isRequired,
    putTagContent: PropTypes.object,
    deleteTagContent: PropTypes.object,
    tagList: PropTypes.array,
    history: PropTypes.object.isRequired,
    userAccount: PropTypes.object,
  }

  static defaultProps = {
    tagList: [],
    putTagContent: {},
    deleteTagContent: {},
    userAccount: {},
  }

  constructor(props) {
    super(props);
    this.displayAutocompleteInput = this.displayAutocompleteInput.bind(this)
    this.validate = this.validate.bind(this)
    this.resetValidMsg = this.resetValidMsg.bind(this)
    this.deleteItem = this.deleteItem.bind(this)
    this.modifyItem = this.modifyItem.bind(this)
    this.onChange = this.onChange.bind(this)
    this.filterOptions = this.filterOptions.bind(this)
    this.getOptionLabel = this.getOptionLabel.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.getModifiedTag = this.getModifiedTag.bind(this)
    this.updateTag = this.updateTag.bind(this)
    this.state = {
      actionLabel: 'Create',
      tagList: [],
      tagContent: '',
      isNewTag: false,
      userId: localStorage.getItem('userId'),
      token: localStorage.getItem('token'),
      isModalOpen: false,
      validateError: false,
      errorMsg: null,
      validMsg: null,
      disabledActionBtn: true,
    }
  }

  componentDidMount() {
    const { userId } = this.state
    const {
      history,
      tagList,
      GET_TAG_LIST,
    } = this.props
    // check if user is logged
    if (!localStorage.getItem('isLogged')) {
      history.push('/')
    }
    if (!_.isEmpty(tagList)) {
      this.setState({
        tagList: _.sortBy(tagList, o => o.tag),
      })
    } else {
      GET_TAG_LIST(userId)
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      tagContent,
      tagList: tagListOld,
    } = prevState
    const {
      tagList,
      putTagContent,
      deleteTagContent,
      RESET_TAG_CONTENT,
    } = nextProps
    console.log(deleteTagContent)
    if (putTagContent && putTagContent.success) {
      return {
        tagContent: {
          ...tagContent,
          id_tag: putTagContent.id,
        },
        validMsg: 'the tag was successfully recorded !',
      }
    }
    // tag delete in DB -> get back updated list
    if (deleteTagContent && deleteTagContent.success) {
      console.log('yaya')
      return {
        tagContent: '',
        validMsg: 'the tag was successfully deleted !',
      }
    }
    // tag can not be delete in DB -> it is used yet in route
    if (deleteTagContent && !deleteTagContent.success) {
      RESET_TAG_CONTENT()
      return {
        validateError: true,
        errorMsg: deleteTagContent.message,
      }
    }
    if (tagList !== tagListOld) {
      return {
        tagList: _.sortBy(tagList, o => o.tag),
      }
    }
    return null; // Aucune mise à jour d'état
  }

  componentDidUpdate() {
    const {
      putTagContent,
      deleteTagContent,
    } = this.props

    if (deleteTagContent && deleteTagContent.success) {
      console.log('yoyo')
      this.reload()
      this.resetValidMsg()
    }

    if (putTagContent && putTagContent.success) {
      this.reload()
      this.resetValidMsg()
      this.handleClose()
    }
  }


  resetValidMsg =() => {
    setTimeout(() => {
      this.setState({
        validMsg: null,
      })
    }, 3000)
  }

  reload = () => {
    const { userId } = this.state
    const {
      RESET_TAG_CONTENT,
      GET_TAG_LIST,
    } = this.props
    RESET_TAG_CONTENT()
    GET_TAG_LIST(userId)
    this.setState({ validateError: false })
  }

  // *******************************
  // On Input value change

  onChange = (event, newValue) => {
    const { tagList } = this.state
    this.setState({
      validateError: false,
    })

    if (typeof newValue === 'string') {
      // On press Enter
      const valueExist = tagList.filter(d => d.tag === newValue)
      if (_.isEmpty(valueExist)) {
        this.setState({
          tagContent: {
            id_tag: null,
            tag: newValue,
          },
          disabledActionBtn: false,
          isNewTag: true,
        });
      } else {
        this.setState({
          tagContent: valueExist[0],
          disabledActionBtn: true,
          isNewTag: false,
        });
      }
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      const isNew = _.findIndex(tagList, o => o.tag === newValue.inputValue) < 0
      this.setState({
        tagContent: {
          id_tag: null,
          tag: newValue.inputValue,
        },
        disabledActionBtn: !isNew,
        isNewTag: isNew,
      });
    } else {
      this.setState({
        tagContent: newValue,
        disabledActionBtn: true,
        isNewTag: false,
      });
    }
  }

  filterOptions = (options, params) => {
    const { tagList } = this.state
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    const valueExist = tagList.filter(d => d.tag === params.inputValue)

    // Suggest the creation of a new value
    if (params.inputValue !== '' && _.isEmpty(valueExist)) {
      filtered.push({
        inputValue: params.inputValue,
        tag: `Add "${params.inputValue}"`,
      });
    }

    return filtered;
  }

  getOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.tag;
  }

  // ***************************
  // Display input

  displayAutocompleteInput = () => {
    const {
      tagContent,
      tagList,
      validateError,
    } = this.state
    const {
      userAccount,
    } = this.props
    const tagListLang = tagList.filter(cL => cL.langage === userAccount.language)


    return (
      <CssGrid
        container
        spacing={2}
      >
        <CssGrid item md={10} xs={12}>
          <Autocomplete
            id="tag-field"
            value={tagContent}
            onChange={this.onChange}
            filterOptions={this.filterOptions}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={tagListLang}
            getOptionLabel={this.getOptionLabel}
            renderOption={option => option.tag}
            freeSolo
            renderInput={params => (
              <Input
                {...params}
                label="Tag"
                margin="normal"
                placeholder="Select a tag or create a new one"
                variant="outlined"
                required
                error={validateError ? params.inputProps.value === '' : false}
              />
            )}
          />
        </CssGrid>
        <CssGrid item md={1} xs={2} className="option-div">
          <div className="option-div-css" onClick={this.modifyItem}>
            <Tooltip title="Modify title" aria-label="modify">
              <img src={modify} srcSet={`${modify2x}, ${modify3x} 3x`} alt={modify} />
            </Tooltip>
          </div>
        </CssGrid>
        <CssGrid item md={1} xs={2} className="option-div">
          <div className="option-div-css" onClick={this.deleteItem}>
            <Tooltip title="Delete" aria-label="delete">
              <img src={deleteCross} srcSet={`${deleteCross2x}, ${deleteCross3x} 3x`} alt={deleteCross} />
            </Tooltip>
          </div>
        </CssGrid>
      </CssGrid>
    );
  }

  // *******************************
  // Delete
  deleteItem = () => {
    const {
      token,
      tagContent,
    } = this.state
    const tagId = tagContent.id_tag
    const { DELETE_TAG_CONTENT } = this.props

    if (confirm('Do you really want to delete this tag ?')) {
      if (tagId) {
        DELETE_TAG_CONTENT(token, tagId)
      } else {
        this.setState({
          validateError: true,
          errorMsg: tagContent.tag ? 'This tag do not exist.' : 'Please, select a tag.',
        })
      }
    }
  }

  // Modify title
  modifyItem = () => {
    const { tagContent } = this.state
    if (!tagContent || !tagContent.id_tag) return null
    this.setState({
      isNewTag: false,
    })
    this.handleOpen()
    return null
  }

  // *******************************
  // Validate
  validate = (e) => {
    e.preventDefault()
    const {
      tagContent,
      isNewTag,
      token,
    } = this.state

    const {
      PUT_TAG_CONTENT,
      userAccount,
    } = this.props

    this.setState({
      disabledActionBtn: true,
    })

    // Check all required inputs
    if (!tagContent.tag) {
      this.setState({
        validateError: true,
        errorMsg: 'Please, fill all required inputs',
      })
      return null
    }
    // Create if it's new
    if (isNewTag) {
      PUT_TAG_CONTENT(token, { tag: tagContent.tag, langage: userAccount.language }, 'create')
    }
    return null
  }

  // **************************
  // Modal action

  // YES response : create new location
  updateTag = () => {
    const {
      token,
      tagContent,
    } = this.state
    const { PUT_TAG_CONTENT } = this.props
    PUT_TAG_CONTENT(token, tagContent, 'update')
  }

  getModifiedTag = (item) => {
    const { tagContent } = this.state
    console.log(item)
    this.setState({
      tagContent: {
        ...tagContent,
        tag: item.pension,
      },
    }, () => this.updateTag())
  }

  // Modal
  handleOpen = () => {
    this.setState({ isModalOpen: true });
  };

  handleClose = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const {
      actionLabel,
      tagContent,
      isModalOpen,
      validMsg,
      validateError,
      errorMsg,
      disabledActionBtn,
    } = this.state
    return (
      <div className="container">
        <Template>
          <div className="requestContainer">
            <div className="management">
              <h2>TAG MANAGEMENT</h2>
              {validateError ? <div className="errorMsg">{errorMsg}</div> : null}
              {validMsg ? <div className="validMsg">{validMsg}</div> : null}
              {this.displayAutocompleteInput()}
            </div>
            <ActionButton
              label={actionLabel}
              disabled={!tagContent || disabledActionBtn}
              onClick={e => this.validate(e)}
            />
          </div>
          <ModalComponent
            isModalOpen={isModalOpen}
            onClose={this.handleClose}
            onResponseNo={this.handleClose}
            onResponseYes={item => this.getModifiedTag(item)}
            disabled={false}
            title="Enter the new value for this tag"
            modalContent="pension"
            warning="This will update this tag in all quotes !"
            type="pension"
            inputValue={!_.isEmpty(tagContent) ? tagContent.tag : ''}
          />
        </Template>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  tagList: state.api.tagList,
  putTagContent: state.api.putTagContent,
  deleteTagContent: state.api.deleteTagContent,
  userAccount: state.userAccountReducer.userAccount,
})

const mapDispatchToProps = dispatch => ({
  GET_TAG_LIST: userId => dispatch({
    type: 'GET_TAG_LIST',
    userId,
  }),
  DELETE_TAG_CONTENT: (token, tagId) => dispatch({
    type: 'DELETE_TAG_CONTENT',
    token,
    tagId,
  }),
  RESET_TAG_CONTENT: () => dispatch({
    type: 'RESET_TAG_CONTENT',
  }),
  PUT_TAG_CONTENT: (token, tagContent, action) => dispatch({
    type: 'PUT_TAG_CONTENT',
    token,
    tagContent,
    action,
  }),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tag))
