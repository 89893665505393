import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import config from '../config'
import {
  SignIn,
  SignUp,
  ChangePassword,
  RecoverPassword,
  Trip,
  TripDetails,
  DaysList,
  TripExtension,
  TripSite,
  Accomodation,
  Day,
  DaysTable,
  Location,
  Location_grand,
  Pension,
  Tag,
  Category,
  Room,
  Activity,
  ActivitiesTable,
  AccomodationType,
  Print,
  Account,
  Company,
  Informations,
  Extension,
  Statistics,
  Billing,
  TripSuppliers,
  Collaborators,
  Request,
  Suppliers,
  Gallery,
  TourOperator,
  TravelerManagement,
  TravelerTable,
  RequestorManagement,
  RequestorTable,
  ResourcesSchedule,
  ResourcesManagement,
  ResourcesList,
  Publicview,
  AccomodationTable,
  HomePage,
  Invoice,
} from '../pages'

const App = () => (
  <Router basename={config.baseUrl}>
    <div className="routes">
      <Route exact path="/" component={SignIn} />
      <Route exact path="/public/:id" component={Publicview} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/changePassword" component={ChangePassword} />
      <Route exact path="/recoverPassword" component={RecoverPassword} />
      <Route exact path="/homePage" component={HomePage} />
      <Route exact path="/invoice" component={Invoice} />
      <Route exact path="/trip" component={Trip} />
      <Route exact path="/tripDetails" component={TripDetails} />
      <Route exact path="/daysList" component={DaysList} />
      <Route exact path="/tripExtension" component={TripExtension} />
      <Route exact path="/tripSite" component={TripSite} />
      <Route exact path="/accomodation" component={Accomodation} />
      <Route exact path="/day" component={Day} />
      <Route exact path="/days-table" component={DaysTable} />
      <Route exact path="/accomodation-table" component={AccomodationTable} />
      <Route exact path="/location" component={Location} />
      <Route exact path="/location_grand" component={Location_grand} />
      <Route exact path="/suppliers" component={Suppliers} />
      <Route exact path="/pension" component={Pension} />
      <Route exact path="/tag" component={Tag} />
      <Route exact path="/category" component={Category} />
      <Route exact path="/room" component={Room} />
      <Route exact path="/activity" component={Activity} />
      <Route exact path="/activities-table" component={ActivitiesTable} />
      <Route exact path="/accomodationType" component={AccomodationType} />
      <Route exact path="/informations" component={Informations} />
      <Route exact path="/extension" component={Extension} />
      <Route exact path="/print" component={Print} />
      <Route exact path="/myAccount" component={Account} />
      <Route exact path="/company" component={Company} />
      <Route exact path="/statistics" component={Statistics} />
      <Route exact path="/billing" component={Billing} />
      <Route exact path="/tripSuppliers" component={TripSuppliers} />
      <Route exact path="/gallery" component={Gallery} />
      <Route exact path="/collaborators" component={Collaborators} />
      <Route exact path="/request" component={Request} />
      <Route exact path="/tourOperator" component={TourOperator} />
      <Route exact path="/traveler-management" component={TravelerManagement} />
      <Route exact path="/traveler-table" component={TravelerTable} />
      <Route exact path="/requestor-management" component={RequestorManagement} />
      <Route exact path="/requestor-table" component={RequestorTable} />
      <Route exact path="/schedule" component={ResourcesSchedule} />
      <Route exact path="/resources-management" component={ResourcesManagement} />
      <Route exact path="/resources-table" component={ResourcesList} />
    </div>
  </Router>
)

export default App
